import { css } from '@emotion/core';
import styled from '@emotion/styled';
import iconBlankWhite from 'assets/images/icons/icon-blank-white.svg';
import iconBlank from 'assets/images/icons/icon-blank.svg';
import Logo from 'components/atoms/logo';
import { IndexLink } from 'components/molecules/indexLink';
import { Link } from 'gatsby';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import colors from 'style/colors';
import fonts from 'style/fonts';
import sizes from 'style/sizes';
import { baseTransitionSecond, easeOut, isIe, mq, maxWidthPC } from 'style/variables';
import { useIsSp } from 'util/useIsSp';

const ClassNames = require('classnames');
const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;
const navs = [
  { name: 'Projects', link: '/projects' },
  { name: 'Services', link: '/services' },
  { name: 'About', link: '/about' },
  { name: 'Topics', link: '/topics' },
  { name: 'Career', link: 'https://recruit.4digit.com/' },
  { name: 'Contact', link: '/contact' },
];

type HeaderProps = {
  locationPathname: string;
};

const langs = ['jp', 'en'];

interface IsJaAboutProps {
  isJaAbout?: boolean;
}
const Container = styled('header')<IsJaAboutProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9000;
  opacity: 0;
  transition: transform ${baseTransitionSecond * 2}s ${easeOut}, opacity ${baseTransitionSecond * 2}s ${easeOut};
  will-change: transform, opacity;
  background-color: #fff;
  .is-loaded & {
    opacity: 1;
    &.is-scrollDown {
      opacity: 0;
      transform: translate3d(0, -100%, 0);
    }
  }
  ${mq.onlypc} {
    max-width: ${maxWidthPC}px;
    margin-left: auto;
    margin-right: auto;
  }
  ${mq.onlysp} {
    max-width: 100vw;
  }
  ${({ isJaAbout }) =>
    isJaAbout
      ? css`
          background-color: unset;
        `
      : null}
`;

const HeaderInner = styled('div')<IsJaAboutProps>`
  height: ${sizes.header.height.pc};
  align-items: center;
  display: flex;
  justify-content: space-between;
  will-change: transform, opacity;
  ${mq.onlypc} {
    padding: 0 47px;
  }
  ${mq.onlysp} {
    height: ${sizes.header.height.sp};
    padding: 0 0 0 25px;
  }
  ${isIe} {
    background-color: rgba(255, 255, 255, 0.7);
    [data-page='top'].is-pagetop & {
      background-color: transparent;
    }
    ${({ isJaAbout }) =>
      isJaAbout
        ? css`
            background-color: unset;
          `
        : null}
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.7;
    ${({ isJaAbout }) =>
      isJaAbout
        ? css`
            background-color: unset;
          `
        : null}
    [data-page='top'].is-pagetop & {
      display: none;
    }
    ${isIe} {
      display: none;
    }
  }
`;

const HeaderLogo = styled(Link)`
  display: block;
  width: 57px;
  z-index: 9999;
  svg {
    width: 100%;
  }
  ${mq.onlysp} {
    width: 44px;
  }
`;

const HeaderInnerRight = styled('div')`
  display: flex;
  position: relative;
`;

const HeaderNav = styled('nav')`
  ${mq.onlysp} {
    position: relative;
  }
`;

const HeaderNavList = styled('ul')`
  ${mq.onlypc} {
    display: flex;
    height: 100%;
  }
`;

const HeaderNavItem = styled('li')`
  ${mq.onlypc} {
    display: flex;
    align-items: center;
  }
  ${mq.onlysp} {
    margin-bottom: 41px;
  }
  &:not(:last-child) {
    ${mq.onlypc} {
      margin-right: 41px;
    }
  }
  a {
    position: relative;
    /* text-transform: uppercase; */
    font-weight: 500;
    display: block;
    font-family: ${fonts.enGothic};

    font-size: 14px;
    ${mq.onlysp} {
      font-size: 3.2rem;
      line-height: normal;
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
      background-color: ${colors.black01};
      margin: auto;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform ${baseTransitionSecond * 1.5}s ease, opacity ${baseTransitionSecond}s ease;
    }
    ${mq.onlypc} {
      &:hover {
        &::before {
          transform: scaleX(1);
          transform-origin: left;
        }
      }
    }

    &[target='_blank'] {
      &::after {
        content: '';
        display: inline-block;
        width: 8px;
        height: 9px;
        background-image: url(${iconBlank});
        background-size: contain;
        margin-left: 4px;
        ${mq.onlysp} {
          width: 14px;
          height: 16px;
          margin-left: 8px;
          background-image: url(${iconBlankWhite});
        }
      }
    }
  }
  &.is-active {
    ${mq.onlypc} {
      a {
        &:before {
          transform: scaleX(1);
          opacity: 0.5;
        }
      }
    }
  }
`;

const HeaderLangList = styled('div')`
  ${mq.onlypc} {
    height: 30px;
    margin-left: 38px;
  }
  &.is-mouseenter {
    ${mq.onlypc} {
      & > ul {
        &::before {
          opacity: 1;
        }
      }
    }
  }
  & > ul {
    ${mq.onlypc} {
      position: relative;
      margin-top: -7px;
      padding: 0 9px 15px;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #e6e9e9;
        transition: opacity ${baseTransitionSecond}s ease;
        opacity: 0;
      }
    }
    ${mq.onlysp} {
      display: flex;
      position: relative;
    }
  }
`;

const HeaderLangItem = styled('li')`
  ${mq.onlypc} {
    text-align: center;
    font-size: 1.3rem;
    line-height: 2;
    & + & {
      margin-top: 10px;
    }
  }
  ${mq.onlysp} {
    & + li {
      &::before {
        content: '/';
        font-size: 2rem;
        display: inline-block;
        padding: 0 1em;
      }
    }
  }
  &:not(.is-active) {
    ${mq.onlypc} {
      opacity: 0;
      transform: translate3d(0, -0.3em, 0);
      transition: opacity ${baseTransitionSecond}s ease, transform ${baseTransitionSecond}s ease;
    }
    .is-mouseenter & {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
`;

const HeaderLangLink = styled(Link)`
  position: relative;
  font-family: ${fonts.enGothic};

  font-size: 16px;
  text-transform: uppercase;
  transition: color ${baseTransitionSecond}s ease;
  color: ${colors.white01};
  ${mq.onlypc} {
    display: block;
    padding-bottom: 6px;
    .is-active > & {
      &::before {
        left: 0;
        right: 0;
        bottom: 2px;
        width: 0;
        height: 0;
        margin: auto;
        border-style: solid;
        border-width: 4px 10px 0 10px;
        border-color: currentColor transparent transparent transparent;
        transition: border-color ${baseTransitionSecond}s ease;
      }
    }
    &:hover {
      color: ${colors.white01};
    }
  }
  ${mq.onlysp} {
    font-size: 2rem;
    .is-active > & {
      &::after {
        width: 100%;
        transform-origin: left;
        transform: scale3d(1, 1, 1);
      }
    }
  }
  &::before {
    content: '';
    display: block;
    position: absolute;
  }
`;

const HeaderBtn = styled('button')`
  ${mq.onlypc} {
    display: none;
  }
  ${mq.onlysp} {
    position: relative;
    width: 80px;
    height: 80px;
    margin-left: ${sizes.margin[49]};
    z-index: 9999;

    &:hover {
      outline: 0;
    }
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 24px;
      margin: auto;
      background-color: ${colors.black01};
      .is-menuOpened & {
        background-color: ${colors.white01};
      }
    }
    &::before {
      width: 24px;
      height: 2px;
      top: -6px;
      bottom: 0;
      transition: transform ${baseTransitionSecond}s ease;
      .is-menuOpened & {
        top: 2px;
        left: 0;
        right: 0;
        width: 24px;
        transform: rotate3d(0, 0, 1, 145deg);
      }
    }
    &::after {
      width: 12px;
      height: 2px;
      top: 6px;
      bottom: 0;
      /* border-radius: 50%; */
      transition: ${baseTransitionSecond / 2}s, width ${baseTransitionSecond / 2}s ease ${baseTransitionSecond / 2}s,
        transform ${baseTransitionSecond / 2}s ease;
      .is-menuOpened & {
        top: 2px;
        left: 0;
        right: 0;
        width: 24px;
        border-radius: 0;
        transform: rotate3d(0, 0, 1, 35deg);
        transition: height ${baseTransitionSecond / 2}s ease, width ${baseTransitionSecond / 2}s ease,
          transform ${baseTransitionSecond / 2}s ease ${baseTransitionSecond / 2}s;
      }
    }
  }
`;

const HeaderMenu = styled.div`
  ${mq.onlysp} {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 8000;
    color: ${colors.white01};
    opacity: 0;
    pointer-events: none;
    transition: opacity ${baseTransitionSecond}s ease;
    overflow: scroll;
    background-color: ${colors.black02};
    -webkit-overflow-scrolling: touch;
    .is-menuOpened & {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

const HeaderMenuInner = styled.div`
  ${mq.onlypc} {
    display: flex;
  }
  ${mq.onlysp} {
    position: relative;
    padding: ${sizes.margin[100]} ${sizes.margin[32]} ${sizes.margin[180]};
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: ${colors.black02};
    }
  }
`;

const Header: React.FC<HeaderProps> = props => {
  const headerMenu = useRef<HTMLDivElement>(null);
  const [directory, setDirectory] = useState('');
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const [isMouseEnter, setisMouseEnter] = useState(false);
  const [langState, setLangState] = useState('jp');
  const isSp = useIsSp();
  const [isScrollDown, setIsScrollDown] = useState(false);
  let scrollPos = 0;
  const isJaAbout = !props.locationPathname.includes('/en') && props.locationPathname.endsWith('/about');

  const scrollHandler = useCallback(e => {
    setIsScrollDown(scrollPos < 100 ? false : scrollPos < window.pageYOffset);
    scrollPos = window.pageYOffset;
  }, []);

  const toggleClass = useCallback((mouseEnter: boolean) => {
    setisMouseEnter(mouseEnter);
  }, []);

  const scrollLock = (e: Event) => {
    e.preventDefault();
  };

  const menuBtnHandler = () => {
    if (!isSp) return;
    headerMenu.current?.scrollTo(0, 0);
    if (!isMenuOpened) {
      disableBodyScroll(headerMenu.current);
    } else {
      enableBodyScroll(headerMenu.current);
    }
    setIsMenuOpened(!isMenuOpened);
  };

  useEffect(() => {
    const path = location.pathname.split('/');
    setDirectory(langState === 'en' ? path[2] : path[1]);
    setLangState(path.includes('en') ? 'en' : 'jp');
  });
  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => {
      enableBodyScroll(headerMenu.current);
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  const classNames = ClassNames({
    'is-menuOpened': isMenuOpened,
    'is-scrollDown': isScrollDown,
  });

  return (
    <Container className={classNames} isJaAbout={isJaAbout}>
      <HeaderInner isJaAbout={isJaAbout}>
        <HeaderLogo to={langState === 'jp' ? '/' : '/en'}>
          <Logo type={isMenuOpened ? 'white' : 'black'} />
        </HeaderLogo>

        <HeaderInnerRight>
          <HeaderMenu ref={headerMenu}>
            <HeaderMenuInner>
              <HeaderNav>
                <HeaderNavList>
                  {navs.map(nav => (
                    <HeaderNavItem
                      key={nav.name}
                      className={nav.link.indexOf(directory, 0) > 0 ? 'is-active' : undefined}
                    >
                      {nav.link.match('http') ? (
                        <a href={nav.link} target="_blank">
                          {nav.name}
                        </a>
                      ) : (
                        <IndexLink
                          onClick={() => menuBtnHandler()}
                          to={`${langState === 'en' ? '/en' : ''}${nav.link}`}
                        >
                          {nav.name}
                        </IndexLink>
                      )}
                    </HeaderNavItem>
                  ))}
                </HeaderNavList>
              </HeaderNav>
              <HeaderLangList
                className={isMouseEnter ? 'is-mouseenter' : undefined}
                onMouseEnter={() => toggleClass(true)}
                onMouseLeave={() => toggleClass(false)}
              >
                {isSp ? (
                  <ul>
                    {langs.map(lang => (
                      <HeaderLangItem className={lang === langState ? 'is-active' : undefined} key={lang}>
                        <HeaderLangLink onClick={() => menuBtnHandler()} to={`/${lang !== 'jp' ? lang : ''}`}>
                          {lang}
                        </HeaderLangLink>
                      </HeaderLangItem>
                    ))}
                  </ul>
                ) : (
                  <ul>
                    <HeaderLangItem className="is-active">
                      <HeaderLangLink to={langState === 'jp' ? '/' : `/${langState}`}>{langState}</HeaderLangLink>
                    </HeaderLangItem>
                    {langs
                      .filter(item => {
                        return item !== langState;
                      })
                      .map((lang, idx) => (
                        <HeaderLangItem key={idx}>
                          <HeaderLangLink to={`/${lang !== 'jp' ? lang : ''}`}>{lang}</HeaderLangLink>
                        </HeaderLangItem>
                      ))}
                  </ul>
                )}
              </HeaderLangList>
            </HeaderMenuInner>
          </HeaderMenu>
          <HeaderBtn aria-label="menu button" onClick={() => menuBtnHandler()} />
        </HeaderInnerRight>
      </HeaderInner>
    </Container>
  );
};

export default Header;
