const sizes = {
  // TODO SP,PCでどうわけるか
  font: {},
  margin: {
    17: '17px',
    16: '16px', //Rbrで追加
    24: '24px', //Rbrで追加
    32: '32px',
    40: '40px', //Rbrで追加
    44: '44px', //Rbrで追加
    48: '48px', //Rbrで追加
    49: '49px',
    60: '60px',
    64: '64px', // Rbrで追加
    56: '56px', //Rbrで追加
    80: '80px', //Rbrで追加
    81: '81px',
    90: '90px', //Rbrで追加
    96: '96px',
    100: '100px', // Rbrで追加
    120: '120px', // Rbrで追加
    113: '113px',
    124: '124px',
    145: '145px',
    155: '155px',
    160: '160px', // Rbrで追加
    177: '177px',
    180: '180px', // Rbrで追加
    192: '192px',
    209: '209px',
    226: '226px',
    241: '241px',
    273: '273px',
    305: '305px',
    337: '337px',
  },
  object: {
    15: '15px',
    32: '32px',
    47: '47px',
    64: '64px',
    96: '96px',
    128: '128px',
    160: '160px',
    192: '192px',
    224: '224px',
    256: '256px',
    288: '288px',
    320: '320px',
  },
  header: {
    height: {
      pc: '108px',
      sp: '80px',
    },
  },
};

export default sizes;
